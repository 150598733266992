import {
  createContext,
  useMemo,
  useContext,
  useState,
  useReducer,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import actions from '../store/actions/app/actions';
import * as asyncActions from '../store/actions/app/asyncActions';
import bindActionCreators from '../util/bindActionCreators';

const preventDefault = e => {
  e && e[0] && e[0].preventDefault && e[0].preventDefault();
};
const AppContext = createContext<any>({});

const AppContextProvider = props => {
  const { actions, asyncActions, children, appState } = props;
  const [requestLiveDemoUrl, setRequestLiveDemo] = useState(null);
  const [isMagicPopLoading, setMagicPopLoader] = useState(false);
  const currentVariantSkuRef = useRef('');
  const appApi = useMemo(
    () => ({
      actions,
      requestLiveDemoUrl,
      currentVariantSkuRef,
      setRequestLiveDemo,
      isMagicPopLoading,
      setMagicPopLoader,
      ...asyncActions,
    }),
    [actions, asyncActions, requestLiveDemoUrl, isMagicPopLoading],
  );
  
  const moveRef = useRef(true);
  useEffect(() => {
    var activityEvents = [
      'mousedown',
      'mousemove',
      'keydown',
      'touchstart',
      'custom',
      'psudoFirstActivity'
    ];
    function activity(e) {
      if (moveRef.current) {
        let event = new Event('firstActivity');
        window.dispatchEvent(event)
      }
      moveRef.current = false;
    }
    activityEvents.forEach(function (eventName) {
      document.addEventListener(eventName, activity, true);
    });
    return () => {
      activityEvents.forEach(function (eventName) {
        document.removeEventListener(eventName, activity, true);
      });
    };
  }, [moveRef]);
  // const contextValue = useMemo(() => [appState, appApi], [appApi, appState]);
  // console.log('contextValue', contextValue)

  return <AppContext.Provider value={appApi}>{children}</AppContext.Provider>;
};

// const AppContextProvider = (props) => {
//   const { children, mitter, menu, storeConfig, content, blogCategories } =
//     props;
//   // console.log('props',props);
//   const [deviceType, setDeviceType] = useState(props.deviceType);

//   // useWindowSize(({ height, width }) => {
//   //   // { vtablet: '769px', vdesktop: '1024px' }
//   //   let newDeviceType = "mobile";
//   //   if (width > 1024) {
//   //     newDeviceType = "desktop";
//   //   } else if (width > 769) {
//   //     newDeviceType = "tablet";
//   //   }
//   //   if (deviceType != newDeviceType) {
//   //     // Refresh complete all for device type change
//   //     setDeviceType(newDeviceType);
//   //   }
//   // }, deviceType);

//   const [modal, setModal] = useState<any>("");
//   const onModal = (...args) => {
//     const [modal] = args;
//     setModal(modal);
//   };

//   mitter && mitter.on("onModal", onModal);

//   const [state, _dispatch] = useReducer(reducer, initialState);

//   const dispatch = useCallback(contextThunk(_dispatch, {}), []);

//   const appState = {
//     deviceType,
//     mitter,
//     isMobile: deviceType == "mobile",
//     isTablet: deviceType == "tablet",
//     isDesktop: deviceType == "desktop",
//     modal,
//     menu,
//     storeConfig,
//     ...state,
//   };

//   const contextValue = useMemo(() => {
//     const appApi = {
//       closeModals: () => {
//         mitter.emit("onModal", null);
//       },
//       openLoginModal: (...e) => {
//         preventDefault(e);
//         mitter.emit("onModal", "common.login", e?.slice(1));
//         return false;
//       },
//       openRegisterModal: (...e) => {
//         preventDefault(e);
//         mitter.emit("onModal", "common.register", e?.slice(1));
//         return false;
//       },
//       openForgotPasswordModal: (...e) => {
//         preventDefault(e);
//         mitter.emit("onModal", "common.forgotPassword", e?.slice(1));
//         return false;
//       },
//       openModal: (model, ...args) => {
//         mitter.emit("onModal", ...args);
//       },
//       actions: {
//         toggleDrawer: (payload) => dispatch(actions.toggleDrawer.action(payload)),
//         toggleSearch: (payload) => dispatch(actions.toggleSearch.action(payload)),
//         setOnline: (payload) => dispatch(actions.setOnline.action(payload)),
//         setOffline: (payload) => dispatch(actions.setOffline.action(payload)),
//         setPageLoading: (payload) => dispatch(actions.setPageLoading.action(payload)),
//         setNextRootComponent: (payload) => dispatch(actions.setNextRootComponent.action(payload))
//       },
//     };
//     return [appState, appApi];
//   }, [appState]);

//   return (
//     <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
//   );
// };

// const mapStateToProps = ({ app }) => ({ appState: app });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(null, mapDispatchToProps)(AppContextProvider);

export const useAppContext = () => useContext(AppContext);
export const useAppState = () => useSelector((state: any) => state.app);
