import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { connect, useSelector } from 'react-redux';

import { gql, useMutation } from '@apollo/client';

import actions from '../store/actions/cart/actions';
import * as asyncActions from '../store/actions/cart/asyncActions';
import bindActionCreators from '../util/bindActionCreators';
import { useEventListener } from '../hooks/useEventListener';
import BrowserPersistence from '../util/simplePersistence';
import { useAwaitQuery } from '../hooks/useAwaitQuery';

const CartContext = createContext<any>({});
const storage = new BrowserPersistence();

const isCartEmpty = cart =>
  !cart || !cart.details.items || cart.details.items.length === 0;

const getTotalQuantity = items =>
  items.reduce((total, item) => total + item.quantity, 0);

const CartUpdateView = props => {
  const cartState = useSelector((state: any) => state.cart);

  const storageListener = useCallback(() => {
    const currentCartId = storage.getItem('cartId');
    const { cartId } = cartState;
    if (/* cartId &&  */ currentCartId && cartId !== currentCartId) {
      globalThis.location && globalThis.location.reload();
    }
  }, [cartState]);

  useEventListener(globalThis, 'storage', storageListener);
  return null;
};

const CartContextProvider = props => {
  const { actions, asyncActions, children } = props;

  const cartApi = useMemo(
    () => ({
      actions,
      ...asyncActions,
    }),
    [actions, asyncActions],
  );

  const [fetchCartId] = useMutation(CREATE_CART_MUTATION);
  const [abandonedCart] = useMutation(ABANDONED_RECOVER);
  const [recoverAbandonedCart] = useMutation(ABANDONED_RECOVER_CART);
  const fetchCartDetails = useAwaitQuery(CART_DETAILS_QUERY);

  useEffect(() => {
    const firstActivityListener = e => {
      cartApi.getCartDetails({
        fetchCartId,
        fetchCartDetails,
      });
    };
    window.addEventListener('firstActivity', firstActivityListener);
    return () => {
      window.removeEventListener('firstActivity', firstActivityListener);
    };
  }, [cartApi, fetchCartDetails, fetchCartId]);

  useEffect(() => {
    // need to replace or this quote_id in storage
    const params = new URLSearchParams(window.location.search);
    const quote_id = parseInt(params.get('quote_id'));
    const token = params.get('token');
    const cart_id = params.get('cart_id');

    function recoveringAbndonedCart(quote_id: Number, token: String) {
      try {
        abandonedCart({
          variables: {
            quote_id,
            token,
          },
        });
      } catch (err: any) {
        console.error('An error occurred during abndonedCartRecover', err);
      }
    }
    if (quote_id && token) recoveringAbndonedCart(quote_id, token);

    function recoverAbandonedCartByCartId(cart_id: String, token: String) {
      try {
        recoverAbandonedCart({
          variables: {
            cart_id,
            token,
          },
        });
      } catch (err: any) {
        console.error('An error occurred during abndonedCartRecover', err);
      }
    }

    if (cart_id && token) recoverAbandonedCartByCartId(cart_id, token);
  }, []);

  return (
    <CartContext.Provider value={cartApi}>
      {children} <CartUpdateView />
    </CartContext.Provider>
  );
};

const mapStateToProps = null; //({ cart }) => ({ cartState: cart });

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
  asyncActions: bindActionCreators(asyncActions, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartContextProvider);

export const useCartContext = () => useContext(CartContext);
export const useCartState = () => {
  const cartState = useSelector((state: any) => state.cart);

  const cartStateValue = useMemo(() => {
    const derivedDetails = (() => {
      if (isCartEmpty(cartState)) {
        return {
          currencyCode: 'USD',
          numItems: 0,
          subtotal: 0,
        };
      } else {
        return {
          currencyCode: cartState.details.prices.grand_total.currency,
          numItems: getTotalQuantity(cartState.details.items),
          subtotal: cartState.details.prices.grand_total.value,
        };
      }
    })();
    const derivedCartState = {
      ...cartState,
      isEmpty: isCartEmpty(cartState),
      derivedDetails,
    };

    return derivedCartState;
  }, [cartState]);
  return cartStateValue;
};

/**
 * We normally do not keep GQL queries in Peregrine. All components should pass
 * queries to talons/hooks. This is an exception to the rule because it would
 * be unecessarily complex to pass these queries to the context provider.
 */
const CREATE_CART_MUTATION = gql`
  mutation createCart {
    cartId: createEmptyCart
  }
`;

const CART_DETAILS_QUERY = gql`
  query checkUserIsAuthed($cartId: String!) {
    cart(cart_id: $cartId) {
      # The purpose of this query is to check that the user is authorized
      # to query on the current cart. Just fetch "id" to keep it small.
      id
      callback
    }
  }
`;

export const CART_ITEMS_QUERY = gql`
  query getItemsQuery($cartId: String!) {
    cart(cart_id: $cartId) {
      id
      items {
        uid
        quantity
        ... on ConfigurableCartItem {
          configured_variant {
            uid
            sku
          }
        }
      }
    }
  }
`;

export const ABANDONED_RECOVER = gql`
  mutation recoverAbandonedCart($quote_id: Int!, $token: String!) {
    recoverAbandonedCart(quote_id: $quote_id, token: $token) {
      message
      status
    }
  }
`;

export const ABANDONED_RECOVER_CART = gql`
  mutation recoverAbandonedCartWithCartString(
    $cart_id: String!
    $token: String!
  ) {
    recoverAbandonedCartWithCartString(cart_id: $cart_id, token: $token) {
      message
      status
      data
    }
  }
`;
